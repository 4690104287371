import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Ehandel from '../components/ehandel'
import PageHeader from '../components/pageHeader'

const campaign = () => (
  <Layout>
    <PageHeader text='Våra kampanjerbjudanden' />
    <Seo title='Kampanjerbjudanden' />
    <Ehandel />
  </Layout>
)

export default campaign
