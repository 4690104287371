import React from 'react'
class YourComponent extends React.Component {
// GAMMLA ASTA WEB E HANDEL ANVÄNDS EJ MERA

  // componentDidMount () {
  //   const script = document.createElement('script'); script.async = true; script.src = 'https://ecommerce.str.se/static/js/str_ecommerce_app.js'; this.div.appendChild(script)
  // }

  render () {
    return (
      <div className='container'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
          {/* <div className='App' ref={el => (this.div = el)}> */}
            {/* <div id='str-ecom' data-customer-number='10875' data-base-path='/e-handel' data-ga-id='UA-012345678-9 ' data-header='headerId' /> */}
          {/* </div> */}
          <h1>Just nu ligger vår e-handel tillfälligt nere pga systemförändringar</h1>
          <h1>Har du frågor angende priser eller paket går det bra att kontakta oss på trafikskolan så hjälper vi dig</h1>
          <h1>Mvh Receptionen 08-755 33 63</h1>
        </div> 
      </div>
    )
  }
}

export default YourComponent
